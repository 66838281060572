import { Controller } from 'stimulus'
import '../vendor/cookiebanner'

export default class extends Controller {
  static values = {
    message: String
  }

  connect() {
    const cookieBanner = new Cookiebanner(
      {
        bg: null,
        closePrecedes: false,
        closeStyle: { float: 'none' },
        closeText: 'OK',
        fg: null,
        fontFamily: null,
        fontSize: null,
        linkmsg: null,
        message: this.messageValue,
        padding: null
      }
    )

    cookieBanner.run()
  }
}
